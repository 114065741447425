import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentBlock from "../shared/sub/contentBlock"
// import ContentPopUp from "../shared/sub/ContactPopUp"

import ContactContentStyle from "../contact-us/ContactContent.style"

const ContactThankYouContent = () => {
  const {
    contactDetails,
    sanityContactUsThankYouPage,
  } = useStaticQuery(graphql`
    query ContactQuery {
      contactDetails: sanityContactUsPage {
        title

        contactDetails {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
      sanityContactUsThankYouPage {
        title
        thankYouPageTextSection {
          _rawContent
        }
      }
    }
  `)

  return (
    <ContactContentStyle>
      <div className="contact-container container">
        <div className="contact-details">
          <ContentBlock blocks={contactDetails.contactDetails._rawContent} />
        </div>
        {/* <ContentPopUp width="45%" /> */}
        <div className="form">
          <ContentBlock
            blocks={
              sanityContactUsThankYouPage.thankYouPageTextSection._rawContent
            }
          />
        </div>
      </div>
    </ContactContentStyle>
  )
}

export default ContactThankYouContent
