import React from "react"
import ContactUsThankYouSEO from "../components/contactUsThankYou/contactThankYouSEO"
import ContactUsThankYouHeader from "../components/contactUsThankYou/contactThankYouHeader"
import ContactThankYouContent from "../components/contactUsThankYou/contactUsThankYouContent"

const ContactUsThankYou = () => {
  return (
    <div className="subpage-container">
      <ContactUsThankYouSEO />
      <ContactUsThankYouHeader />
      <ContactThankYouContent />
    </div>
  )
}

export default ContactUsThankYou
